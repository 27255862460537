//@ts-nocheck
const validateEmail = (email) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const userDetailValidator = (data) => {
  let error = {};
  if (!data.firstName || data.firstName === '' || !data.firstName.length > 3) {
    error.firstName = 'Enter your first name (minimum length = 3)';
  }
  if (!data.lastName || data.lastName === '') {
    error.lastName = 'Enter your last name';
  }
  if (
    !data.email ||
    data.email === '' ||
    !validateEmail(data.email).length > 0
  ) {
    error.email = 'Enter a valid email';
  }
  if (!data.brn || data.brn === '' || data.brn.length < 3) {
    error.brn = 'Enter a valid Broker Registration Number (minimum length =5)';
  }
  if (
    !data.phoneNumber ||
    data.phoneNumber === '' ||
    data.phoneNumber.length < 9
  ) {
    error.phoneNumber = 'Enter a valid Phone  Number (minimum length)';
  }

  // if (!data.password || data.password === "" || data.password.length < 6) {
  //   error.password = "Enter a valid password (minimum length = 6)";
  // }
  if (
    !data.accountType ||
    data.accountType === '' ||
    data.accountType.length < 3
  ) {
    error.accountType = 'Select your account type';
  }
  return error;
};
const companyDetailValidator = (data) => {
  let error = {};

  if (!data.name || data.name === '' || !data.name.length > 3) {
    error.name = 'Enter your Company name (minimum length = 3)';
  }
  if (
    !data.tradeLicenseNumber ||
    data.tradeLicenseNumber === '' ||
    !data.tradeLicenseNumber.length > 3
  ) {
    error.tradeLicenseNumber = 'Enter your Trade License Number ';
  }
  if (!data.crmUrl || data.crmUrl === '' || !data.crmUrl.length > 3) {
    error.crmUrl = 'Enter your CRM URL';
  }
  if (!data.crmName || data.crmName === '' || !data.crmName.length > 3) {
    error.crmName = 'Enter your CRM Name';
  }

  if (
    !data.email ||
    data.email === '' ||
    !validateEmail(data.email).length > 0
  ) {
    error.email = 'Enter a valid email';
  }

  if (
    !data.phoneNumber ||
    data.phoneNumber === '' ||
    data.phoneNumber.length < 9
  ) {
    error.phoneNumber = 'Enter a valid Phone  Number (minimum length)';
  }

  if (
    !data.accountType ||
    data.accountType === '' ||
    data.accountType.length < 3
  ) {
    error.accountType = 'Select your account type';
  }
  return error;
};
export { companyDetailValidator, userDetailValidator, validateEmail };
