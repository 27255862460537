import TerminalButton from '@/Components/Buttons/TerminalButton';
import Iuser from '@/Components/DTO/Iuser';
import DialogModals from '@/Components/Modals/DialogModals';
import DialogModalsPending from '@/Components/Modals/DialogModalsPending';
import ErrorIconSvg from '@/Components/SVG/ErrorIconSvg';
import styles from '@/styles/page/LoginRegister/loginRegisterMain.module.scss';
import { useLoading } from '@/utils/LoadingContext';
import { ModalContext } from '@/utils/ModalContext';
import { validateEmail } from '@/utils/validators';
import { GetServerSidePropsContext } from 'next';
import { getServerSession } from 'next-auth';
import { getSession, signIn, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { authOptions } from '../api/auth/[...nextauth]';
const SignIn = () => {
  const { openModal } = useContext(ModalContext);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { setLoadingState } = useLoading();
  const router = useRouter();
  const redirect = String(router.query.redirect || '/');
  const [userData, setuserData] = useState({
    accountType: 'Individual',
    email: '',
    password: '',
  });
  const [loginError, setloginError] = useState({
    accountType: false,
    email: false,
    password: false,
  });
  const [updateError, setupdaetError] = useState({
    phoneNumber: false,
    reraCard: false,
    budgetExpertise: false,
    profilePic: false,
    budget: false,
  });

  const [userDetails, setuserDetails] = useState<Iuser>();
  const budgetItems = [
    { value: 'Ultra', label: 'Ultra-Luxury Properties (50M+)' },
    { value: 'Elite', label: 'Elite Properties (20M-50M)' },
    { value: 'Premier', label: 'Premier Properties (10M-20M)' },
    { value: 'Luxury', label: 'Luxury Properties (5M-10M)' },
    { value: 'Affordable', label: 'Affordable Properties (500K-5M)' },
  ];

  const [selectedFile, setSelectedFile] = useState();
  const changeHandler = (event: any) => {
    if (updateError.profilePic) {
      setupdaetError({ ...updateError, profilePic: false });
    }
    setSelectedFile(event.target.files[0]);
  };
  const [selectedReraCard, setselectedReraCard] = useState();
  const changeHandlerRera = (event: any) => {
    if (updateError.reraCard) {
      setupdaetError({ ...updateError, reraCard: false });
    }
    setselectedReraCard(event.target.files[0]);
  };

  const [selectedBusinessCard, setselectedBusinessCard] = useState();
  const changeHandlerBusiness = (event: any) => {
    setselectedBusinessCard(event.target.files[0]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loginError.accountType) {
      setloginError({ ...loginError, accountType: false });
    }
    setuserData({ ...userData, accountType: event.target.value });
  };
  useEffect(() => {
    const getSessions = async () => {
      const session = await getSession();
      const user = session?.user;
    };
    getSessions();
  }, []);

  const HandleLogin = async () => {
    setLoadingState(true);
    if (userData.email.length > 0 && userData.password.length >= 6) {
      if (
        validateEmail(userData.email) &&
        validateEmail(userData.email).length > 0
      ) {
        const resp = await signIn('Credentials', {
          // Pass the credentials here (e.g., email and password)
          email: userData.email,
          password: userData.password,
          redirect: false,

          callbackUrl: `/`,
          // Add any additional options as needed
        });
        // const resp = await api.post(
        //   userData.accountType === 'Individual'
        //     ? '/auth/login-user'
        //     : '/auth/login-company',
        //   userData
        // );

        if (resp?.ok) {
          router.push(redirect ? redirect : '/');

          // console.log(user, session, 'session');
          // if (redirect && redirect !== undefined) {
          //   if (!resp.data.data.reraCard) {
          //     Cookies.set(
          //       'userdetails',
          //       JSON.stringify({
          //         ...resp.data.data,
          //         reraCard: '',
          //         businessCard: '',
          //         budgetExpertise: [],
          //       }),
          //       {
          //         expires: 1,
          //       }
          //     );
          //     router.push('/login/profileupdate');

          //     // setuserDetails({
          //     //   ...resp.data.data,
          //     //   reraCard: '',
          //     //   businessCard: '',
          //     //   budgetExpertise: [],
          //     // });
          //   } else {
          //     Cookies.set('userData', JSON.stringify(resp.data), {
          //       expires: 1,
          //     });
          //     router.push(location.href.split('redirect=')[1]);
          //   }
          // }
          //  else {
          //   if (!resp.data.data.reraCard) {
          //     setOpen(true);
          //     Cookies.set(
          //       'userdetails',
          //       JSON.stringify({
          //         ...resp.data.data,
          //         reraCard: '',
          //         businessCard: '',
          //         budgetExpertise: [],
          //       }),
          //       {
          //         expires: 1 / 48,
          //       }
          //     );
          //     router.push('/login/profileupdate');
          //     setuserDetails({
          //       ...resp.data.data,
          //       reraCard: '',
          //       businessCard: '',
          //       budgetExpertise: [],
          //     });
          //   } else {
          //     Cookies.set('userData', JSON.stringify(resp.data), {
          //       expires: 1,
          //     });

          //     updateUser?.updateUser(resp.data?.data);
          //     router.push(`/`);
          //   }

          //   //
          // }
        }
        // } else if (resp.data.message === 'Expired') {
        //   openModal(
        //     <DialogModals
        //       action={() => {
        //         router.push('/login/forgot-password');
        //       }}
        //       title="Warning"
        //       type="warning"
        //       message={
        //         'Your Account has Expired, To Recover your account Please Reset Your Accounts Password: https://brokerterminal.com/login/forgot-password'
        //       }
        //     />
        //   );
        // } else if (resp.data.message === 'PENDING') {
        //   openModal(
        //     <DialogModalsPending
        //       action={() => {
        //         router.push('/');
        //       }}
        //       title="Warning"
        //       type="warning"
        //       label="Back to Homepage"
        //       message={`Dear ${resp.data.data.firstName}, <br/> <br/>

        //         Your account is currently undergoing a security review. Your login details remain valid. We will notify you via email once the review is complete.
        //         <br/><br/>
        //         Thank you for your patience.
        //         <br/><br/>
        //         Best regards,<br/>
        //         Brokerterminal`}
        //     />
        //   );
        // }
        else if (resp?.error) {
          const errors = JSON.parse(resp?.error);
          console.log('errors', errors);
          if (errors?.message === 'Expired') {
            openModal(
              <DialogModals
                action={() => {
                  router.push('/login/reset-password');
                }}
                title="Warning"
                type="warning"
                message={
                  'Your Account has Expired, To Recover your account Please Reset Your Accounts Password: https://brokerterminal.com/login/reset-password'
                }
              />
            );
          } else if (
            errors?.message === 'PENDING' ||
            errors?.message === 'APV-PENDING'
          ) {
            openModal(
              <DialogModalsPending
                action={() => {
                  router.push('/');
                }}
                title="Warning"
                type="warning"
                label="Back to Homepage"
                message={`Dear ${errors?.data.firstName}, <br/> <br/>

                Your account is currently undergoing a security review. Your login details remain valid. We will notify you via email once the review is complete.
                <br/><br/>
                Thank you for your patience.
                <br/><br/>
                Best regards,<br/>
                Brokerterminal`}
              />
            );
          } else if (
            errors?.message === 'IPassword' ||
            errors?.message === 'Invalid password'
          ) {
            openModal(
              <DialogModals
                action={() => {}}
                title="Password Error"
                type="error"
                label="Back to Login"
                message={`Oops, Tt looks like the password you entered is incorrect. Please try again.`}
              />
            );
          } else if (
            errors?.message === 'IEmail' ||
            errors?.message === 'Invalid email'
          ) {
            openModal(
              <DialogModals
                action={() => {}}
                title="Email Error"
                type="error"
                label="Back to Login"
                message={`Sorry, we couldn't find an account with that email address`}
              />
            );
          } else {
            openModal(
              <DialogModals
                action={() => {}}
                title="Error"
                type="error"
                message={'failed'}
              />
            );
          }
        } else {
          openModal(
            <DialogModals
              action={() => {}}
              title="Error"
              type="error"
              message={'failed'}
            />
          );
        }
      } else {
        setloginError({ ...loginError, email: true });
      }
    } else if (userData.email.length === 0) {
      setloginError({ ...loginError, email: true });
    } else if (userData.password.length < 6) {
      setloginError({ ...loginError, password: true });
    }
    setLoadingState(false);
  };

  const UserContext = useSession().data?.user;
  useEffect(() => {
    if (UserContext && UserContext && !UserContext.verified) {
      router.push('/login/profileupdate');
    }
  }, []);

  //   useEffect(() => {
  //     openModal(
  //       <DialogModals
  //         action={async () => {
  //           router.push('/');
  //         }}
  //         title="Info!"
  //         type="info"
  //         label="Go HomePage"
  //         message={`We are currently upgrading our backend systems, which has temporarily made login access unavailable.

  // We apologize for the inconvenience and will notify you as soon as access is restored.

  // Thank you for your understanding.

  // Best regards,`}
  //       />
  //     );

  //     return () => {};
  //   }, []);

  useEffect(() => {
    if (!isMobile) {
      openModal(
        <DialogModals
          action={async () => {
            router.push('/');
          }}
          title="Info!"
          type="info"
          label="Go HomePage"
          message={`Login dashboard is currently only available on mobile browsers. Please visit from a mobile device for the best experience`}
        />
      );
    }

    return () => {};
  }, [isMobile]);
  return (
    <main className={styles.mainContainer_main}>
      <div className={styles.mainContainer_main_logo}>
        {/* <Image
            src={terminalLogo}
            alt="BrokerTerminal"
            onClick={() => router.push('/')}
            width={200}
            height={45}
          /> */}
      </div>
      <div className={styles.mainContainer_main_details}>
        <header className={styles.mainContainer_main_details_header}>
          <h1>Login to Broker Terminal</h1>
          <span>
            Don&apos;t have an account yet?{' '}
            <Link href={'/register'}>Sign Up</Link>{' '}
          </span>
        </header>
        <main className={styles.mainContainer_main_details_main}>
          {/* <div className={styles.mainContainer_main_details_main_radios}>
              <FormControl color="primary">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  row
                  onChange={handleChange}
                  value={userData.accountType}
                >
                  <FormControlLabel
                    value="Individual"
                    control={<Radio />}
                    checked={userData.accountType === 'Individual'}
                    label="Individual broker"
                  />
                  <FormControlLabel
                    value="Company"
                    checked={userData.accountType === 'Company'}
                    control={<Radio />}
                    label="Company account"
                  />
                </RadioGroup>
              </FormControl>
            </div> */}
          <div className={styles.mainContainer_main_details_main_inputs}>
            <label htmlFor="loginEmail">
              <>Email</>{' '}
            </label>
            <div
              className={styles.mainContainer_main_details_main_inputs_border}
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: loginError.email
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="loginEmail"
                placeholder="Enter your Email"
                type="email"
                value={userData.email}
                onChange={(e) => {
                  if (loginError.email) {
                    setloginError({ ...loginError, email: false });
                  }
                  setuserData({ ...userData, email: e.target.value });
                }}
              />
            </div>
            {loginError.email && userData.email.length > 0 && (
              <nav
                onClick={() => {
                  setloginError({ ...loginError, email: false });
                  setuserData({ ...userData, email: '' });
                }}
              >
                <ErrorIconSvg />
              </nav>
            )}
            {loginError.email ? <span>Enter a valid Email.</span> : <></>}
          </div>
          <div className={styles.mainContainer_main_details_main_inputs}>
            <label htmlFor="loginPassword">
              <>Password</>{' '}
            </label>{' '}
            <div
              className={styles.mainContainer_main_details_main_inputs_border}
            >
              <input
                autoFocus={false}
                autoComplete="off"
                style={{
                  border: loginError.password
                    ? '1px solid var(--primary)'
                    : 'none',
                }}
                id="loginPassword"
                type="password"
                placeholder="Enter your Password"
                value={userData.password}
                onChange={(e) => {
                  if (loginError.password) {
                    setloginError({ ...loginError, password: false });
                  }
                  setuserData({ ...userData, password: e.target.value });
                }}
              />
            </div>
            {loginError.password && userData.password.length > 0 && (
              <nav
                onClick={() => {
                  setloginError({ ...loginError, password: false });
                  setuserData({ ...userData, password: '' });
                }}
              >
                <ErrorIconSvg />
              </nav>
            )}
            {loginError.password ? <span>Enter a valid Password.</span> : <></>}{' '}
          </div>
          <Link
            className={styles.mainContainer_main_details_main_span}
            href={'/login/reset-password'}
          >
            Reset Password
          </Link>
        </main>
        <footer className={styles.mainContainer_main_details_footer}>
          <TerminalButton
            position="ll"
            width="100%"
            label="LogIn"
            onClick={() => {
              HandleLogin();
            }}
          />
        </footer>
      </div>
    </main>
  );
};

export default SignIn;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions);
  const { query } = context;
  // If the user is already logged in, redirect.
  // Note: Make sure not to redirect to the same page
  // To avoid an infinite loop!
  if (session) {
    const redirectUrl = query.redirect || '/';

    if (session && session?.user && !session?.user.verified) {
      const redirectUrl = query.redirect || '/login/profileupdate';

      return { redirect: { destination: redirectUrl } };
    }
    return { redirect: { destination: redirectUrl } };
  }

  return {
    props: { nth: 'nth' },
  };
}
